.shareSheetContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: fixed;
  height: 188px;
  width: 42px;
  z-index: 1;
  right: 4%; //TODO: Look into how to center this along FabWrapper OR implement MUI speed dial
}

.shareButton {
  margin: 5px;
}

.copyIconDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7F7F7F;
  color: white;
  // border: 2px solid red;
  // box-shadow: 5px 10px 18px #888888;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  // margin-top: 5px;
  // margin-left: 5px;
  // margin-right: 5px;
}

.copyLink {
  height: 31px;
  width: 31px;
  margin: 5px;
}
