@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

.textIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15vh;
  // border: 2px solid red;
}

.p {
  text-decoration: none;
}

.title {
  font-weight: 400;
  font-size: 64px;
  font-family: "Montserrat", sans-serif;
}

.instructions {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
}

.tangerineIcon {
  margin-top: 10px;
  max-height: 56px;
  max-width: 56px;
}

.ijnaLogo {
  max-height: 150px;
  max-width: 150px;
  margin: 100px 0 64px 0;
}

// .copyConfirmationContainer {
//   position: fixed;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   top: 26vh;
//   z-index: 1;
// }

.copyConfirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 19px 46px;
  border-radius: 16px;
  height: 25px;
  width: 70px;
  padding: 5px;
  top: 100px;
  background: white;
  box-shadow: 4px 4px 6px gray;
  font-family: "Montserrat", sans-serif;
}

#homeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  // border: 2px solid blue;
  // width: calc(100vw - (100vw - 100%));
}

.editorDiv {
  display: flex;
  justify-content: center;
}

hr {
  width: 50%;
  max-width: 300px;
  margin-top: 14px;
  height: 1px;
  background-color: rgb(0, 0, 0);
  border: none;
}

.copyRightInfo {
  margin: 0 0 116px 0;
  font-family: "Roboto Mono";
  font-size: 18px;
}

//Phone
@media only screen and (max-width: 600px) { 
  .title {
    font-weight: 400;
    font-size: 64px;
    // margin-top: 45vh;
  }
  .instructions {
    font-size: 18px;
  }
  // .tangerineIcon {
  //   margin-top: 312px;
  //   max-height: 56px;
  //   max-width: 56px;
  // }
  #homeContainer {
    height: 100vh;
    width: 100vw;
  }
}

// Tablet
@media screen and (min-device-width: 600px) and (max-device-width: 1024px) {
  // #homeContainer {
  //   height: 95vh;
  // }

  .title {
    font-weight: 400;
    font-size: 64px;
    // margin-top: 75%;
  }
}

// /* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {...}

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {...}

// /* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {...}

// /* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {...}

// /* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {...}