.shareButton {
    // margin: 5px;
    border: 2px solid red;
}

.menuItem {
    border: 2px solid blue;
}

// .copyIconDiv {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: #7f7f7f;
//     color: white;
//     // border: 2px solid red;
//     // box-shadow: 5px 10px 18px #888888;
//     border-radius: 50%;
//     height: 100%;
//     width: 100%;
//     // margin-top: 5px;
//     // margin-left: 5px;
//     // margin-right: 5px;
// }

.copyLink, .downloadLink, .emailLink {
    display: flex;
    height: 31px;
    width: 31px;
    margin: 5px;
    text-decoration: none;
    color: black;
}