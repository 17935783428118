@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

// .navContainer {
//   // !position: device-fixed;
//   position: fixed;
//   // top: 0;
//   // left: 0;
//   width: 100%;
//   background-color: white;
//   z-index: 1;
//   border: 2px solid red;
//   // display: -webkit-flex;
//   // -webkit-justify-content: flex-end;
//   // display: flex;
//   // justify-content: flex-end;
//   // -webkit-flex-direction: column;
//   // transform: translate3d(0, 0, 0);
//   // -webkit-transform: translate3d(0, 0, 0);
//   -webkit-transform: translateZ(0);
// }

// .logoContainer {
//   width: 10vw;
//   height: 5vh;
// }

// .navLogo {
//   width: 100%;
//   height: 100%;
// }

.navContainer {
  z-index: 2;
  // border: 2px solid red;
  position: fixed;
  width: 100%;
  // min-height: 56px;
  // margin-bottom: 5px;
}

.toolbar {
  border-bottom: 2px solid #000000;
  background-color: #FDDAA5;
  // border: 2px solid red;
  display: flex;
  justify-content: flex-end;
}