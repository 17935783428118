@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

html {
  // border: 2px solid blue;
}
.editorContainer {
  body {
    overflow: hidden; /* Hide scrollbars */
    // overflow: auto; /* Hide scrollbars */
    font-family: "Montserrat", sans-serif;
    // border: 2px solid red;
  }
}

// button {
//   background: #999;
//   color: #fff;
//   border: none;
//   padding: 0.5em;
//   cursor: pointer;
//   margin-bottom: .3em;
//   border-radius: 0.2em;
// }

// button:hover {
//   background: #888;
// }

.editorContainer {
  display: flex;
  justify-content: center;
  position: fixed;
  margin-top: 80px;
  // border: 2px solid blue;
  height: 90%;
}

textarea {
  font-size: large;
  font-family: "Montserrat";
  resize: none;
  // margin-top: 75px;
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
  // border: 2px solid blue;
  // height: 85vh;
  width: 78vw;
  max-width: 800px;
  padding: 0 16vw 0;
  line-height: 1.6;
}

// Phone
@media only screen and (max-width: 600px) {
  textarea {
    // margin-top: 65px;
    // height: 100%;
    // border: 2px solid red;
    // ! for textarea border - too large
    // border: 2px solid black;
    // border-radius: 10px;
    // ! 
    padding: 0 30px 0;
  }

  .editorContainer {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    // border: 2px solid red;
    height: 85%;
  }

  .editorContainerKeyboard {
    display: flex;
    justify-content: center;
    position: fixed;
    margin-top: 80px;
    // border: 2px solid blue;
    height: 75%;
  }

  .editorContainerKeyboardIos {
    display: flex;
    justify-content: center;
    position: fixed;
    margin-top: 80px;
    // border: 2px solid black;
    // border-radius: 10px;
    height: 25vh;
  }
}

// Tablet
@media screen and (min-device-width: 600px) and (max-device-width: 1024px) {
  textarea {
    // border: 2px solid blue;
    padding: 0 165px 0;
  }

  .editorContainer {
    display: flex;
    justify-content: center;
    position: fixed;
    margin-top: 80px;
    // border: 2px solid red;
    height: 90%;
  }

  .editorContainerKeyboard {
    display: flex;
    justify-content: center;
    position: absolute;
    margin-top: 80px;
    // border: 2px solid blue;
    height: 55%;
  }

  .editorContainerKeyboardIos {
    display: flex;
    justify-content: center;
    position: absolute;
    margin-top: 80px;
    // border: 2px solid black;
    // border-radius: 10px;
    height: 50vh;
  }

  @media (orientation: landscape) {
    .editorContainerKeyboardIos {
      height: 15vh;
    }
  }
}
