@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shareSheetContainer{display:flex;flex-direction:column;align-items:center;justify-content:center;flex-wrap:wrap;position:fixed;height:188px;width:42px;z-index:1;right:4%}.shareButton{margin:5px}.copyIconDiv{display:flex;justify-content:center;align-items:center;background:#7F7F7F;color:white;border-radius:50%;height:100%;width:100%}.copyLink{height:31px;width:31px;margin:5px}

h2{margin-top:32px}.aboutHeader{margin-bottom:24px;font-family:"Montserrat", sans-serif;font-weight:normal}.aboutParagraph{margin:0 0 0 0;font-size:20px;font-family:"Montserrat", sans-serif}.featuresList{padding-left:16px;margin:0 0 0 0;list-style-type:'- ';font-size:20px;font-family:"Montserrat", sans-serif}.aboutContainer{height:100%;display:flex;justify-content:center;font-weight:400;font-size:22px;font-family:"Montserrat", sans-serif}.aboutContent{max-width:600px;height:100%;margin-top:64px;display:flex;flex-direction:column;justify-content:center}a:link{text-decoration:none}@media only screen and (max-width: 600px){html,body{width:100%;overflow:auto;position:relative}.aboutContent{width:100%;display:flex;margin-top:0;flex-direction:column;padding:0 32px 0 32px}}

.textIconContainer{display:flex;justify-content:center;align-items:center;margin-top:15vh}.p{text-decoration:none}.title{font-weight:400;font-size:64px;font-family:"Montserrat", sans-serif}.instructions{font-family:"Montserrat", sans-serif;font-size:28px}.tangerineIcon{margin-top:10px;max-height:56px;max-width:56px}.ijnaLogo{max-height:150px;max-width:150px;margin:100px 0 64px 0}.copyConfirmation{display:flex;justify-content:center;align-items:center;box-shadow:3px 19px 46px;border-radius:16px;height:25px;width:70px;padding:5px;top:100px;background:white;box-shadow:4px 4px 6px gray;font-family:"Montserrat", sans-serif}#homeContainer{display:flex;flex-direction:column;align-items:center;height:100%}.editorDiv{display:flex;justify-content:center}hr{width:50%;max-width:300px;margin-top:14px;height:1px;background-color:#000;border:none}.copyRightInfo{margin:0 0 116px 0;font-family:"Roboto Mono";font-size:18px}@media only screen and (max-width: 600px){.title{font-weight:400;font-size:64px}.instructions{font-size:18px}#homeContainer{height:100vh;width:100vw}}@media screen and (min-device-width: 600px) and (max-device-width: 1024px){.title{font-weight:400;font-size:64px}}

.navContainer{z-index:2;position:fixed;width:100%}.toolbar{border-bottom:2px solid #000000;background-color:#FDDAA5;display:flex;justify-content:flex-end}

.shareButton{border:2px solid red}.menuItem{border:2px solid blue}.copyLink,.downloadLink,.emailLink{display:flex;height:31px;width:31px;margin:5px;text-decoration:none;color:black}

.editorContainer body{overflow:hidden;font-family:"Montserrat", sans-serif}.editorContainer{display:flex;justify-content:center;position:fixed;margin-top:80px;height:90%}textarea{font-size:large;font-family:"Montserrat";resize:none;border:none;overflow:auto;outline:none;box-shadow:none;resize:none;width:78vw;max-width:800px;padding:0 16vw 0;line-height:1.6}@media only screen and (max-width: 600px){textarea{padding:0 30px 0}.editorContainer{display:flex;justify-content:center;margin-top:80px;height:85%}.editorContainerKeyboard{display:flex;justify-content:center;position:fixed;margin-top:80px;height:75%}.editorContainerKeyboardIos{display:flex;justify-content:center;position:fixed;margin-top:80px;height:25vh}}@media screen and (min-device-width: 600px) and (max-device-width: 1024px){textarea{padding:0 165px 0}.editorContainer{display:flex;justify-content:center;position:fixed;margin-top:80px;height:90%}.editorContainerKeyboard{display:flex;justify-content:center;position:absolute;margin-top:80px;height:55%}.editorContainerKeyboardIos{display:flex;justify-content:center;position:absolute;margin-top:80px;height:50vh}}@media screen and (min-device-width: 600px) and (max-device-width: 1024px) and (orientation: landscape){.editorContainerKeyboardIos{height:15vh}}


.EditorPage_editorPageContainer__2MYnd html,.EditorPage_editorPageContainer__2MYnd body{width:100%;height:1000vh;overflow:hidden;position:fixed}.EditorPage_copyConfirmationContainer__25KkY{position:fixed;width:100%;display:flex;justify-content:center;top:26vh;z-index:1}.EditorPage_copyConfirmation__3BZeR{display:flex;justify-content:center;align-items:center;box-shadow:3px 19px 46px;border-radius:16px;height:25px;width:70px;padding:5px;top:100px;background:white;box-shadow:4px 4px 6px gray;font-family:"Montserrat", sans-serif}.EditorPage_editorDiv__2CK7c{display:flex;justify-content:center}.EditorPage_squirtle__Je8vC{width:200px;position:fixed;margin-top:400px;margin-left:40%}@media only screen and (max-width: 600px){.EditorPage_placeholder__aSwPJ{top:5vh}.EditorPage_editorPageContainer__2MYnd html,.EditorPage_editorPageContainer__2MYnd body{width:100%;height:90vh;overflow:hidden;position:fixed}}@media screen and (min-device-width: 600px) and (max-device-width: 1024px){html,body{width:100%}.EditorPage_fabWrapper__xb_Tf{bottom:80px}}

.privacyHeader{margin-bottom:24px;font-family:"Montserrat", sans-serif;font-weight:normal}.privacyParagraph{margin:0 0 0 0;font-size:20px;font-family:"Montserrat", sans-serif}.privacyInfoContainer{height:100%;display:flex;justify-content:center;font-weight:400;font-size:22px;font-family:"Montserrat", sans-serif}.privacyContent{max-width:600px;height:100%;margin-top:32px;display:flex;flex-direction:column;justify-content:center}a:link{text-decoration:none}#privacyPageContainer{display:flex;flex-direction:column;align-items:center;height:100%}.privacyPolicyHeader{font-family:"Montserrat", sans-serif;font-style:normal;font-weight:400;font-size:64px;line-height:78px;margin-bottom:32px;margin-top:80px;max-width:600px}.privacyEmailLink{margin-bottom:100px}.privacyToolbar{background-color:white;display:flex;align-items:center}@media only screen and (max-width: 600px){.privacyContent{width:100%;display:flex;margin-top:0;flex-direction:column;padding:0 32px 0 32px}}


