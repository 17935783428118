@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

// html, body {
//   height: 100vh;
//   // border: 2px solid red;
// }

h2 {
  margin-top: 32px;
}

.aboutHeader {
  margin-bottom: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
}

.aboutParagraph {
  margin: 0 0 0 0;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}

.featuresList {
  padding-left: 16px;
  margin: 0 0 0 0;
  list-style-type: '- ';
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}

.aboutContainer {
  // border: 2px solid orange;
  height: 100%;
  // width: calc(100vw - (100vw - 100%));
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
}

// .aboutContentContainer {
//   height: 100%;
//   display: flex;
// }

.aboutContent {
  // border: 2px solid red;
  max-width: 600px;
  height: 100%;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// .aboutParagraph:nth-child(2),
// .contact:nth-child(1) {
//   margin: 32px 0 0 0;
//   // border: 2px solid red;
// }

a:link {
  text-decoration: none;
}

// .emailButton {
//   text-transform: lowercase;
//   text-transform: capitalize;
// }

@media only screen and (max-width: 600px) {
  //Phone
  html,
  body {
    width: 100%;
    overflow: auto;
    position: relative;
  }

  .aboutContent {
    // border: 2px solid red;
    width: 100%;
    display: flex;
    margin-top: 0;
    flex-direction: column;
    padding: 0 32px 0 32px;
  }
}
