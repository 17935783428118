@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

.privacyHeader {
  margin-bottom: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
}

.privacyParagraph {
  margin: 0 0 0 0;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}

.privacyInfoContainer {
  // border: 2px solid orange;
  height: 100%;
  // width: calc(100vw - (100vw - 100%));
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
}

// .aboutContentContainer {
//   height: 100%;
//   display: flex;
// }

.privacyContent {
  // border: 2px solid red;
  max-width: 600px;
  height: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

a:link {
  text-decoration: none;
}

#privacyPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.privacyPolicyHeader {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 78px;
  margin-bottom: 32px;
  margin-top: 80px;
  max-width: 600px;
}

.privacyEmailLink {
  margin-bottom: 100px;
}

.privacyToolbar {
  background-color: white;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .privacyContent {
    // border: 2px solid red;
    width: 100%;
    display: flex;
    margin-top: 0;
    flex-direction: column;
    padding: 0 32px 0 32px;
  }
}
