@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.editorPageContainer {
  html,
  body {
    // max-width: 100%;
    // overflow-x: hidden;
    // border: 1px solid red;
    width: 100%;
    height: 1000vh;
    overflow: hidden;
    position: fixed;
  }
}

// .placeholderContainer {
//   display: flex;
//   justify-content: center;
//   pointer-events: none;
// }

// .placeholder {
//   position: fixed;
//   max-height: 78vh;
//   max-width: 100vw;
// }

.copyConfirmationContainer {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 26vh;
  z-index: 1;
}

.copyConfirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 19px 46px;
  border-radius: 16px;
  height: 25px;
  width: 70px;
  padding: 5px;
  top: 100px;
  background: white;
  box-shadow: 4px 4px 6px gray;
  font-family: "Montserrat", sans-serif;
}

.editorDiv {
  display: flex;
  justify-content: center;
}

.squirtle {
  width: 200px;
  position: fixed;
  margin-top: 400px;
  margin-left: 40%;
}

// Phone
@media only screen and (max-width: 600px) {
  .placeholder {
    top: 5vh;
  }

  .editorPageContainer {
    html,
    body {
      // max-width: 100%;
      // overflow-x: hidden;
      // border: 1px solid red;
      width: 100%;
      height: 90vh;
      overflow: hidden;
      position: fixed;
    }
  }
}

// .editorPageContainer {
//   overflow: hidden;
//   height: 100%;
//   width: 100%;
//   position: fixed;
//   border: 2px solid green;
// }

// Tablet
@media screen and (min-device-width: 600px) and (max-device-width: 1024px) {
  html,
  body {
    // max-width: 100%;
    // overflow-x: hidden;
    // border: 1px solid red;
    width: 100%;
  }
  .fabWrapper {
    // border: 2px solid red;
    bottom: 80px;
  }
}
